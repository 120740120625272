import { Component, OnInit } from '@angular/core';
import { Global } from '../global';
import { Router, ActivatedRoute } from '@angular/router';

declare var $;

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {

  siniestro;
  formulario = {
    presupuesto: {
      value: [],
      error: null
    },
    importe: {
      value: '',
      error: null
    },
    datos: {
      value: [],
      error: null
    },
    dni: {
      value: [],
      error: null
    },
    cuenta: {
      value: [],
      error: null
    },
    verificacion: {
      value: '2',
      error: null
    }
  };
  n_files = 1;
  paso = 1;

  subiendo_presupuesto = false;
  subiendo_datos = false;
  subiendo_dni = false;
  subiendo_cuenta = false;
  cargando_ficheros = false;

  constructor(public global: Global, private activatedRoute: ActivatedRoute, private router: Router) { }

  async ngOnInit() {

    await this.getDatosSiniestro();

  }

  async getDatosSiniestro() {

    this.cargando_ficheros = true;

    let result = await this.global.apiCall("goFit/getSiniestro", "POST", false, { id: this.activatedRoute.params['value'].id });

    this.cargando_ficheros = false;

    if (!result.status) {

      alert(result.message);
      this.router.navigateByUrl("/");
      return;

    }

    result.data.sin_fechaSiniestro = new Date(result.data.sin_fechaSiniestro).toLocaleDateString("es-ES");
    result.data.contacto = result.data.SIN_Descripcion.split("La persona de contacto es")[1] || "";
    this.siniestro = result.data;

    this.getNombreRSocial();

  }

  getNombreRSocial() {

    if (this.siniestro.PRS_Nombre == '') this.siniestro.PRS_Nombre = this.siniestro.PRS_Apellido1;

  }

  async subir_documento_presupuesto(fileInput: any) {

    this.subiendo_presupuesto = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      let result = await this.uploadFile(file);

      if(result.message) {
        this.formulario.presupuesto.error = result.message;
        this.subiendo_presupuesto = false;
        $("#input_file_presupuesto").val("");
        return;
      }

      let url = result.url;
      let new_name = result.new_name;

      let type = file.type.split('/')[0];

      this.formulario.presupuesto.value.push({
        url: url,
        type: type,
        name: file.name,
        new_name: new_name
      });

    }

    $("#input_file_presupuesto").val("");

    this.formulario.presupuesto.error = null;

    this.subiendo_presupuesto = false;

    this.n_files++;

  }

  async subir_documento_datos(fileInput: any) {

    this.subiendo_datos = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      let result = await this.uploadFile(file);

      if(result.message) {
        this.formulario.datos.error = result.message;
        $("#input_file_datos").val("");
        this.subiendo_datos = false;
        return;
      }

      let url = result.url;

      let type = file.type.split('/')[0];

      this.formulario.datos.value.push({
        url: url,
        type: type,
        name: file.name,
        new_name: result.new_name
      });

    }

    $("#input_file_datos").val("");

    this.formulario.datos.error = null;

    this.subiendo_datos = false;

    this.n_files++;

  }

  async subir_documento_dni(fileInput: any) {

    this.subiendo_dni = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      let result = await this.uploadFile(file);

      if(result.message) {
        this.formulario.dni.error = result.message;
        this.subiendo_dni = false;
        $("#input_file_dni").val("");
        return;
      }

      let url = result.url;

      let type = file.type.split('/')[0];

      this.formulario.dni.value.push({
        url: url,
        type: type,
        name: file.name,
        new_name: result.new_name
      });

    }

    $("#input_file_dni").val("");

    this.formulario.dni.error = null;

    this.subiendo_dni = false;

    this.n_files++;

  }

  async subir_documento_cuenta(fileInput: any) {

    this.subiendo_cuenta = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      let result = await this.uploadFile(file);

      if(result.message) {
        this.formulario.cuenta.error = result.message;
        this.subiendo_cuenta = false;
        $("#input_file_cuenta").val("");
        return;
      }

      let url = result.url;

      let type = file.type.split('/')[0];

      this.formulario.cuenta.value.push({
        url: url,
        type: type,
        name: file.name,
        new_name: result.new_name
      });

    }

    $("#input_file_cuenta").val("");

    this.formulario.cuenta.error = null;

    this.subiendo_cuenta = false;

    this.n_files++;

  }

  uploadFile(file) {

    let size = file.size / 1024 / 1014;

    if(size >= 20) {
      return {
        status: false,
        message: "No se pueden subir archivos con un tamaño superior a 20Mb"
      };
    }

    var formData = new FormData();

    formData.append('select_file', file, file.name);
    formData.append('sin_id', this.activatedRoute.params['value'].id);
    formData.append('n_files', this.n_files.toString());

    return $.ajax({
      url: this.global.config.api + 'goFit/uploadFile',
      method: "POST",
      data: formData,
      dataType: 'JSON',
      contentType: false,
      cache: false,
      processData: false
    });

  }

  async delete_file(key, i) {

    await this.global.apiCall("goFit/deleteFile", "POST", false, { name: this.formulario[key].value[i].new_name });

    this.formulario[key].value.splice(i, 1);

  }

  abrir_documento(file) {

    window.open(file.url);

  }

  comprobar_campos() {

    let r = true;

    for (let campo in this.formulario) {

      this.formulario[campo].error = null;

    }

    if (this.formulario.presupuesto.value.length == 0) {
      this.formulario.presupuesto.error = "Por favor, adjunte el/los documentos de presupuesto de reparación de daños";
      r = false;
    }

    if (this.formulario.importe.value == "") {
      this.formulario.importe.error = "Por favor, indique el importe del presupuesto de reparación";
      r = false;
    }

    if (!(/^\$?[\d,]+([,\.]\d*)?$/).test(this.formulario.importe.value)) {

      this.formulario.importe.error = "Por favor, indique un importe del presupuesto de reparación válido";
      r = false;

    }

    if (this.formulario.datos.value.length == 0) {
      this.formulario.datos.error = "Por favor, adjunte fotos de los daños";
      r = false;
    }

    if (this.formulario.dni.value.length == 0) {
      this.formulario.dni.error = "Por favor, adjunte una copia del DNI / CIF";
      r = false;
    }

    if (this.formulario.cuenta.value.length == 0) {
      this.formulario.cuenta.error = "Por favor, adjunte el número de cuenta y certificado de titularidad";
      r = false;
    }

    if (this.formulario.verificacion.value == "2") {
      this.formulario.verificacion.error = "Por favor, adjunte un documento donde se visualice el número de cuenta y <<nombre o razón social>> como titular de la misma";
      r = false;
    }

    return r;

  }

  async cargarFicheros() {

    if (!this.comprobar_campos()) {

      alert("Comprueba los datos introducidos");
      return;

    }

    this.cargando_ficheros = true;

    let data = {};

    for (let campo in this.formulario) {

      data[campo] = this.formulario[campo].value;

    }

    data['sin_id'] = this.activatedRoute.params['value'].id;

    let result = await this.global.apiCall("goFit/cargarFicheros", "POST", false, data);

    this.cargando_ficheros = false;

    if (!result.status) {

      alert(result.message);
      return;

    }

    this.paso = 3;

  }

}
