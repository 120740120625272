import {Component, OnInit} from '@angular/core';
import {Global} from '../global';

declare var $;

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.scss']
})
export class AperturaComponent implements OnInit {

  paso = 1;
  apertura = false;
  formulario;
  poblaciones = [];
  cargando = false;
  subiendo_ficha = false;
  confirmEmail = false;
  centros = [];
  causas = [];
  tipologias = [];
  pide = true;
  n_files = {
    ficha_abonado: 0
  };
  grm_id;
  allDocumentos = [];
  files = [];
  documentos = [];
  subiendo = {};
  error_abonado = null;

  constructor(public global: Global) { }

  async ngOnInit() {

    await this.getCentros();
    this.getDocuments();


  }

  async getDocuments() {
    this.cargando = true;
    const result = await this.global.apiCall('goFit/getDocuments', 'POST', false, {});
    this.cargando = false;
    if (result.status == false) {
      if (result.message) { alert(result.message); }
      return;
    }

    this.allDocumentos = result.data;

  }

  async getCentros() {
    this.cargando = true;
    const result = await this.global.apiCall('goFit/getCentros', 'GET', false, {});
    this.cargando = false;

    if (!result.status) {
      alert(result.message);
      return;
    }
    this.centros = result.data;

  }

  async getTipologiaSiniestros() {
    this.cargando = true;
    const result = await this.global.apiCall('goFit/tipologiaSiniestros', 'POST', false, { grm_id: this.grm_id });
    this.cargando = false;
    if (result.status == false) {
      if (result.message) { alert(result.message); }
      return;
    }

    this.tipologias = result.data;
    this.tipologias.push({
      tps_id: -1,
      tps_codigo: -1,
      tps_descripcion: 'No estoy seguro'
    });

  }

  async getCausas() {

    this.causas = [];
    this.formulario.causaSiniestro.value = null;

    if (this.formulario.tipologiaSiniestro.value == null) { return; }
    const tipologia = this.tipologias.find(t => t.tps_id == this.formulario.tipologiaSiniestro.value);
    this.cargando = true;
    const result = await this.global.apiCall('goFit/causaSiniestros', 'POST', false, { tps_codigo: tipologia.tps_codigo });
    this.cargando = false;
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      } else {
        this.causas.push({
          csn_descripcion: 'No estoy seguro',
          csn_id: -1
        });
        this.formulario.causaSiniestro.value = this.causas[0].csn_id;
      }
      return;
    }

    this.causas = result.data;
    if (this.causas.length == 1) {
      this.formulario.causaSiniestro.value = this.causas[0].csn_id;
    } else {
      this.causas.push({
        csn_descripcion: 'No estoy seguro',
        csn_id: -1
      });
    }
  }

  async getGRM() {
    const centro = this.centros.find(c => c.riesgo == this.formulario.centro.value);
    console.log('centro', centro);
    let poliza = '';
    if (this.formulario.tipoSiniestro.value != 'Daños') {
      poliza = centro.pol_id_rc;
      this.formulario.rie_id.value = centro.Rie_id_rc;

    } else {
      poliza = centro.pol_id_daños;
      this.formulario.rie_id.value = centro.Rie_id_da;
    }


    this.cargando = true;
    const result = await this.global.apiCall('goFit/getGRM', 'POST', false, { pol_id: poliza });

    // tslint:disable-next-line:triple-equals
    if (result.status == false) {
      if (result.message) {
        alert(result.message);
      }
      return;
    }
    const data = result.data;

    if (data.grm_id) { this.grm_id = data.grm_id; } else { this.grm_id = data.GRM_Id; }

    this.formulario.pol_id.value = poliza;
    this.formulario.poliza.value = data.poliza;
    this.formulario.nif.value = data.nif;


    const contactodata = await this.global.apiCall('siniestros/getContactoDatos', 'POST', false, {pol_id: poliza});

    const contactDefault = contactodata.data;


    this.formulario.contacto.nombre.value = contactDefault != null ? contactDefault.ctc_nombre : '';
    this.formulario.contacto.telefono.value = contactDefault != null ? contactDefault.ctc_telefono : '';
    this.formulario.contacto.email.value = contactDefault != null ? contactDefault.ctc_email : '';

    this.cargando = false;
    console.log(this.formulario);
  }

  init_formulario() {

    this.formulario = {
      pol_id: {
        value: '',
        paso: 1
      },
      centro: {
        value: '',
        error: null,
        paso: 1
      },
      tipoSiniestro: {
        value: 'Daños',
        error: null,
        paso: 1
      },
      poliza: {
        value: '',
        paso: 1
      },
      rie_id: {
        value: '',
        paso: 1
      },
      nif: {
        value: '',
        paso: 1
      },
      tipologiaSiniestro: {
        value: null,
        error: null,
        paso: 2
      },
      causaSiniestro: {
        value: null,
        error: null,
        paso: 2
      },
      fecha: {
        value: '',
        error: null,
        paso: 2
      },
      perjudicado : {
        nombre: {
          value: '',
          error: null,
          paso: 4,
          tipo_persona: 1,
          danos: true
        },
        apellido1: {
          value: '',
          error: null,
          paso: 4,
          tipo_persona: 1,
          danos: false
        },
        apellido2: {
          value: '',
          error: null,
          paso: 4,
          tipo_persona: 1,
          danos: false
        },
        razonSocial: {
          value: '',
          error: null,
          paso: 4,
          tipo_persona: 2,
          danos: false
        },
        telefono: {
          value: '',
          error: null,
          paso: 4 ,
          danos: false
        },
        email: {
          value: '',
          error: null,
          paso: 4,
          danos: false
        },
        contacto: {
          value: '',
          error: null,
          paso: 3,
          danos: false
        },
        tipo_persona: {
          value: '1',
          error: null,
          paso: 4,
          danos: false
        },
      },
      dirPerjudicado: {
        value: '',
        error: null,
        paso: 2
      },
      cp: {
        value: '',
        error: null,
        paso: 2
      },
      poblacion: {
        value: '',
        error: null,
        paso: 2
      },
      circunstancias: {
        value: '',
        error: null,
        paso: 2
      },
      danos: {
        value: '',
        error: null,
        paso: 2
      },

      contacto: {
        nombre: {
          value: '',
          error: null,
          paso: 3,
          // tipo_persona: 1,
          danos: true
        },
        telefono: {
          value: '',
          error: null,
          paso: 3,
          danos: true
        },
        email: {
          value: '',
          error: null,
          paso: 3,
          danos: true
        },
      },
      sin_id: {
        value: null,
        error: null,
        paso: 'sin_id'
      }
    };

    this.n_files.ficha_abonado = 0;
    this.paso = 1;
    this.poblaciones = [];
    this.files = [];
    this.documentos = [];

    this.iniTable(null);
    setTimeout(() => {
      $('#fecha').datepicker({
        regional: 'es',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
        maxDate: 0,
      }).change(() => {
        this.formulario.fecha.error = null;
      });
      $('#select2Documentos').val(this.documentos);
      $('#select2Documentos').select2({
        dropdownParent: $('#panel'),
        placeholder: 'Selecciona los documentos a añadir',
      }).on('select2:select', (e) => {
        const doc = this.allDocumentos.find(t => t.doc_id == e.params.data.id);
        this.formulario[doc.doc_descripcion] = {
          value: [],
          error: null,
          id: doc.doc_id,
          paso: 'documentos'
        };
        this.subiendo[doc.doc_id] = false;
        this.n_files[doc.doc_id] = 1;
        this.documentos.push(doc);
        if (this.documentos.length == 1) {
          $('#docsTable').DataTable().destroy();
          this.iniTable(0);
        }
      }).on('select2:unselect', (e) => {
        const doc = this.documentos.find(t => t.doc_id == e.params.data.id);
        this.documentos.splice(this.documentos.indexOf(doc), 1);
        if (this.documentos.length == 0) {
          $('#docsTable').DataTable().destroy();
          this.iniTable(0);
        }
      });
    }, 100);

  }

  iniTable(id) {
    setTimeout(() => {
      if (id == 0 || id == null) {
        $('#docsTable').DataTable({
          lengthMenu: [150],
          language: {
            url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          },
        });
      }
      if (id == 1 || id == null) {
        $('#perjudicadosTable').DataTable({
          lengthMenu: [150],
          language: {
            url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          },
        });
      }
    }, 100);
  }

  setTipoSiniestroByCentro() {
    this.formulario.centro.error = null;
    const centro = this.centros.find(c => c.riesgo == this.formulario.centro.value);


    if (centro.daños == '1' && centro.RC == '0') {
      this.formulario.tipoSiniestro.value = 'Daños';
      $('#radioDanos').prop('disabled', true);
      $('#radioRC').prop('disabled', true);
    } else if (centro.daños == '0' && centro.RC == '1') {
      this.formulario.tipoSiniestro.value = 'Responsabilidad Civil';
      $('#radioDanos').prop('disabled', true);
      $('#radioRC').prop('disabled', true);
    } else {
      $('#radioDanos').prop('disabled', false);
      $('#radioRC').prop('disabled', false);
    }
  }

  clickInput(input) {
    document.getElementById('input_' + input).click();
  }

  async comprobar_campos_paso1(evt = null) {
    if (evt) { return false; }

    let r = true;

    if (this.paso == 1) {

      for (const campo in this.formulario) {

        if (this.formulario[campo].paso == 1) {

          this.formulario[campo].error = null;

        }

      }

      if (this.formulario.centro.value == '' || this.formulario.centro.value == null) {
        this.formulario.centro.error = 'Debe seleccionar un centro';
        r = false;
      }
    }

    if (r) {
      await this.getGRM();
      await this.getTipologiaSiniestros();
    }

    return r;

  }


  async comprobar_campos_paso2(evt = null) {

    if (evt) { return false; }

    let r = true;

    if (this.paso == 2) {

      this.formulario.fecha.value = $('#fecha').val();

      for (const campo in this.formulario) {

        if (campo == 'poblacion') { continue; }

        if (this.formulario[campo].paso == 2) {

          this.formulario[campo].error = null;

          if (this.formulario[campo].value == '' || this.formulario[campo].value == null) {
            this.formulario[campo].error = 'Rellena el campo';
            r = false;
          }
        }
      }

      const fecha = new Date(this.convertDataYmd(this.formulario.fecha.value));
      const hoy = new Date();
      const dias = Math.floor((fecha.getTime() - hoy.getTime()) / (1000 * 60 * 60 * 24));

      if (dias >= 0) {
        this.formulario.fecha.error = 'La fecha no puede ser posterior a hoy';
        r = false;
      }

      const c = await this.checkCP(this.formulario.cp.value);

      if (!c) { r = false; }
    }

    return r;

  }

  async comprobar_campos_paso3(evt = null) {

    if (evt) { return false; }

    let r = true;

    if (this.paso == 3) {

      for (const campo in this.formulario) {

        if (this.formulario[campo].paso == 3) {

          // if (campo == "email") continue;

          // if (this.formulario[campo].tipo_persona) {
          //
          //   if (this.formulario[campo].tipo_persona != this.formulario.tipo_persona.value) continue;
          //
          // }

          // if (this.formulario.tipoSiniestro.value == 'Daños') {
          //
          //   if (!this.formulario[campo].danos) continue;
          //
          // }

          this.formulario[campo].error = null;

          if (this.formulario[campo].value == '') {
            this.formulario[campo].error = 'Rellena el campo';
            r = false;
          }
          if (!(/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/).test(this.formulario.contacto.telefono.value)) {

            this.formulario.contacto.telefono.error = 'Introduzca un número de teléfono válido';
            r = false;

          }

          if (this.formulario.contacto.email.value != '') {
            if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.formulario.contacto.email.value))) {

              this.formulario.contacto.email.error = 'Introduzca un email válido';
              r = false;

            }
          }


        }

      }

    }

    return r;

  }

  async pideEmail(value) {

    this.confirmEmail = false;
    this.pide = true;

    if (value == 0) {

      this.pide = false;
      await this.paso4();

    } else {

      this.formulario.email.error = 'Indique el email de contacto';

    }

  }

  async paso2() {

    const c = await this.comprobar_campos_paso1();

    if (!c) { return; }

    this.paso = 2;

  }

  async paso3() {

    const c = await this.comprobar_campos_paso2();

    if (!c) { return; }

    this.paso = 3;

  }

  async paso4() {

    const c = await this.comprobar_campos_paso3();

    if (!c) { return; }

    this.paso = 4;
  }

  async paso5() {
    this.paso = 5;

  }

  abrir_documento(file) {

    window.open(file.url);

  }

  aperturar() {
    this.init_formulario();
    this.apertura = true;
  }

  checkLast() {
    if (this.paso == 5) {
      if (this.formulario.tipoSiniestro.value == 'Responsabilidad Civil') { return true; } else { return false; }
    }
    if (this.paso == 6) { return false; }
    return true;
  }

  cancelarApertura() {
    this.apertura = false;
  }

  async checkCP(cp) {

    this.poblaciones = [];

    this.formulario.cp.error = null;

    if (this.formulario.cp.value == '') {

      this.formulario.cp.error = 'Rellena el campo';
      return false;

    } else if (isNaN(parseFloat(this.formulario.cp.value))) {

      this.formulario.cp.error = 'Introduzca un código postal válido';
      return false;

    } else if (this.formulario.cp.value.length != 5) {

      this.formulario.cp.error = 'Introduzca un código postal válido';
      return false;

    }

    this.cargando = true;
    const result = await this.global.apiCall('goFit/checkCP', 'POST', false, { cp });
    this.cargando = false;

    if (!result.status) {

      this.formulario.cp.error = result.message;
      return false;

    }

    this.poblaciones = result.data;

    return true;

  }

  existsFiles(name) {
    if (this.files.filter(f => f.document == name).length == 0) {
      return false;
    }
    return true;
  }

  async subir_documentos(fileInput: any, documentName, docId) {

    this.subiendo[docId] = true;

    const files = fileInput.target.files as Array<File>;

    for (const file of files) {

      this.files.push({
        file,
        name: file.name,
        document: documentName,
        nombre: documentName + '_' + this.n_files[docId].toString(),
        id: docId
      });

      this.n_files[docId]++;

    }


    $('input[type="file"]').val('');

    this.subiendo[docId] = false;

  }

  async subir_ficha(fileInput: any) {

    this.subiendo_ficha = true;

    if (!this.n_files.ficha_abonado && !this.formulario.ficha_abonado) {
      this.n_files.ficha_abonado = 1;

      this.formulario.ficha_abonado = {
        value: [],
        error: null,
        id: 'ficha_abonado',
        paso: 'documentos'
      };

    }

    const files = fileInput.target.files as Array<File>;

    for (const file of files) {

      this.files.push({
        file,
        name: file.name,
        document: 'ficha_abonado',
        nombre: 'ficha_abonado' + '_' + this.n_files.ficha_abonado.toString(),
        id: 'ficha_abonado'
      });

    }

    this.error_abonado = null;

    this.subiendo_ficha = false;

    this.n_files.ficha_abonado++;

  }

  uploadFile(file, name) {

    const size = file.size / 1024 / 1014;

    if (size >= 20) {
      return {
        status: false,
        message: 'No se pueden subir archivos con un tamaño superior a 20Mb'
      };
    }

    const formData = new FormData();

    formData.append('select_file', file, file.name);
    formData.append('file_name', name);
    formData.append('sin_id', this.formulario.sin_id.value);

    return $.ajax({
      url: this.global.config.api + 'goFit/uploadFile',
      method: 'POST',
      data: formData,
      dataType: 'JSON',
      contentType: false,
      cache: false,
      processData: false
    });

  }

  delete_file_doc(key, i) {

    this.files.splice(i, 1);

  }

  async uploadDocumentos() {

    if (this.documentos.find(d => d.doc_id == 'ficha_abonado') == null && this.formulario.ficha_abonado) {
      this.documentos.push({ doc_id: 'ficha_abonado', doc_descripcion: 'ficha_abonado' });
    }

    for (const file of this.files) {
      this.formulario[file.document].value = [];
    }

    for (const file of this.files) {

      const result = await this.uploadFile(file.file, file.nombre);

      if (result.message) {
        alert('Se ha producido un error en la subida de documentos');
        return;
      }

      const url = result.url;
      const new_name = result.new_name;

      const type = file.file.type.split('/')[0];

      this.formulario[file.document].value.push({
        url,
        type,
        name: file.name,
        new_name
      });
    }
  }


  async grabar_siniestro() {

    let data = {};
    let result;

    console.log('data formulaio a grabar', this.formulario.perjudicado.nombre.value);

    if (this.formulario.sin_id.value == null || !this.formulario.sin_id.value) {

      this.cargando = true;

      //
      data['perjudicado-nombre'] = this.formulario.perjudicado.nombre.value;
      data['perjudicado-apellido1'] = this.formulario.perjudicado.apellido1.value;
      data['perjudicado-apellido2'] = this.formulario.perjudicado.apellido2.value;
      data['perjudicado-razonSocial'] = this.formulario.perjudicado.razonSocial.value;
      data['perjudicado-telefono'] =  this.formulario.perjudicado.telefono.value;
      data['perjudicado-email'] = this.formulario.perjudicado.email.value;
      data['perjudicado-contacto'] = this.formulario.perjudicado.contacto.value;
      data['perjudicado-tipo_persona'] =  this.formulario.perjudicado.tipo_persona.value;


      data['contacto-nombre'] = this.formulario.contacto.nombre.value;
      data['contacto-telefono'] = this.formulario.contacto.telefono.value;
      data['contacto-email'] = this.formulario.contacto.email.value;


      for (const campo in this.formulario) {

        if (this.formulario[campo].paso != 'sin_id' && this.formulario[campo].paso != 'documentos') { data[campo] = this.formulario[campo].value; }

      }

      data['fecha'] = this.convertDataYmd(this.formulario.fecha.value);

      result = await this.global.apiCall('goFit/grabar_siniestro', 'POST', false, data);

      this.cargando = false;

      if (!result.status) {
        alert(result.message);
        return;
      }

      this.formulario.sin_id.value = result.data;

    }

    this.cargando = true;

    await this.uploadDocumentos();

    data = {};

    for (const campo in this.formulario) {

      if (this.formulario[campo].paso != 'sin_id' && this.formulario[campo].paso != 'documentos') { continue; }

      data[campo] = this.formulario[campo].value;

    }

    result = await this.global.apiCall('goFit/cargarFicherosApertura', 'POST', false, { data, docs: this.documentos });

    this.cargando = false;

    if (!result.status) {

      alert(result.message);
      return;

    }
    this.paso = 6;



  }


  convertDataYmd(date) {
    var newdate = date.split('/').reverse().join('/');
    return newdate;
  }



}
